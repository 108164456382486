.banner {
  background: url(./home.jpg) no-repeat top center;
}

.banner h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  text-align: right;
  text-transform: uppercase;
  color: #3342B9;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  padding: 100px 0 210px;
  margin: 0 0;
}